import { tags } from ".prisma/client";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { cities, levels } from "../../../common/constants";
import { fetcher } from "../../../common/helper";
import useSearch from "../../../hooks/useSearch";
import Select from "../Select/Select";

const SearchBox = () => {
    const { data: tagsData } = useSWR<
        { value: string; label: string }[],
        Error
    >("/api/tags/get", fetcher);
    const [location, setLocation] = useState(null);
    const [level, setLevel] = useState(null);
    const [tag, setTag] = useState(null);

    const isSingle =
        location?.length < 2 && level?.length < 2 && tag?.length < 2;

    const { runSearch } = useSearch(location, level, tag, isSingle);

    const router = useRouter();

    useEffect(() => {
        if (router.query) {
            const locationQuery = router.query.lokacija as string;
            const levelQuery = router.query.iskustvo as string;
            const tagQuery = router.query.tag as string;

            const locations = locationQuery?.split(",").map((loc) => ({
                value: loc,
                label: cities.filter((cty) => cty.slug === loc)[0]?.name,
            }));

            const levelsQuery = levelQuery?.split(",").map((loc) => ({
                value: loc,
                label: levels?.filter((lvl) => lvl.slug === loc)[0]?.name,
            }));

            const tags = tagQuery?.split(",").map((loc) => ({
                value: loc,
                label: loc?.slice(0, -4).replaceAll("-", " "),
            }));

            if (locationQuery) {
                setLocation(locations);
            }
            if (levelQuery) {
                setLevel(levelsQuery);
            }
            if (tagQuery) {
                setTag(tags);
            }
        }
    }, [router.query]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-0 md:gap-3 relative max-w-screen-lg m-auto -mt-9 py-7 md:py-10 px-6 border job-shadow rounded-3xl bg-white border-gray-light">
            <div className="relative w-full my-2 md:my-0">
                <Select
                    options={cities.map((cty) => ({
                        value: cty.slug,
                        label: cty.name,
                    }))}
                    isMulti
                    value={location}
                    placeholder="Lokacija"
                    setValue={setLocation}
                    type="lokacija"
                />
            </div>
            <div className="relative w-full my-2 md:my-0">
                <Select
                    options={levels.map((lvl) => ({
                        value: lvl.slug,
                        label: lvl.name,
                    }))}
                    isMulti
                    value={level}
                    placeholder="Iskustvo"
                    setValue={setLevel}
                    type="iskustvo"
                />
            </div>
            <div className="lg:col-span-2 relative w-full my-2 md:my-0">
                <Select
                    options={tagsData}
                    isMulti
                    value={tag}
                    placeholder="Ključna riječ, npr. React"
                    setValue={setTag}
                    type="tag"
                />
            </div>
            <button
                type="button"
                className="btn-primary relative w-full my-2 md:my-0 flex items-center justify-center"
                onClick={runSearch}
            >
                <svg
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="md:hidden"
                >
                    <path
                        d="M1 11.1521C1 13.8446 2.06959 16.4268 3.97348 18.3307C5.87736 20.2346 8.45958 21.3042 11.1521 21.3042C13.8446 21.3042 16.4268 20.2346 18.3307 18.3307C20.2346 16.4268 21.3042 13.8446 21.3042 11.1521C21.3042 8.45958 20.2346 5.87736 18.3307 3.97348C16.4268 2.06959 13.8446 1 11.1521 1C8.45958 1 5.87736 2.06959 3.97348 3.97348C2.06959 5.87736 1 8.45958 1 11.1521V11.1521Z"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18.3301 18.33L23.2129 23.2128"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <span className="inline-block mx-4">Pretraži</span>
            </button>
        </div>
    );
};

export default SearchBox;
