import Link from "next/link";
import { getExpiringInDays } from "../../../common/helper";

import { JobListing } from "../../../common/types";
import ChartIcon from "../Icons/ChartIcon";
import ClockIcon from "../Icons/ClockIcon";
import LocationIcon from "../Icons/LocationIcon";

export default function ListItem(props: { job: JobListing }) {
    const { job } = props;
    const expiringIn = getExpiringInDays(job.expiresAt);
    // @todo sakrije one koji su istekli.

    function getJobColor() {
        if (job.isColored) {
            return "bg-premium-250 border-premium-300  hover:border-premium-400";
        }
        if (job.isPremium) {
            return "bg-premium-100 border-premium-200  hover:border-premium-400";
        }
        return "bg-white border-gray hover:border-blue";
    }

    // Potrebno za pracenje klikova kroz GTM
    function getLinkClass() {
        if (job.isColored) {
            return "premium-colored";
        }
        if (job.isPremium) {
            return "premium";
        }
        return "standard";
    }

    const sallaryDesktopRow = job.sallary ? "md:grid-cols-4" : "md:grid-cols-3";
    const sallaryMobileRow = job.sallary ? "grid-cols-2" : "grid-cols-3";

    return (
        <Link href={`/posao/${job.company.slug}/${job.slug}`}>
            <a className={getLinkClass()}>
                <div
                    className={`job-shadow cursor-pointer border mb-4 pl-8 ${getJobColor()} transition-all duration-300 rounded-xl md:min-h-[118px] w-full flex flex-col md:flex-row justify-between md:items-center transform hover:-translate-y-1 py-6 md:py-6`}
                >
                    <div className="flex mb:mr-7 items-center pb-4 md:pb-0">
                        <div className="w-12 mr-6 rounded overflow-hidden">
                            <img
                                src={job.company.logo}
                                alt={job.company.name}
                                className=""
                            />
                        </div>
                        <div className="">
                            <h3 className="font-medium">{job.title}</h3>
                            <div className="">
                                <div className="text-gray-dark font-light">
                                    {job.company.name}
                                    {job.isRemote && (
                                        <span className="absolute -top-2 -left-2 block bg-black bg-opacity-80 text-white text-xs rounded-md px-2 py-1">
                                            remote
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {job.isPremium === 1 && (
                            <span className="absolute right-0 top-0 text-xs bg-premium-300 text-white px-2 py-1 rounded-tr-md">
                                premium
                            </span>
                        )}
                    </div>
                    <div
                        className={`grid ${sallaryMobileRow} ${sallaryDesktopRow} max-w-lg w-full`}
                    >
                        <div className={`${job.sallary ? "mb-4 md:mb-0" : ""}`}>
                            <div className="flex text-gray-dark text-sm items-center">
                                <LocationIcon />
                                <div>Lokacija</div>
                            </div>
                            <div className="md:mt-2 font-medium">
                                {job.locations.length > 40
                                    ? `${job.locations.slice(0, 40)}...`
                                    : job.locations}
                                {!job.locations && "Remote"}
                            </div>
                        </div>
                        <div className={`${job.sallary ? "mb-4 md:mb-0" : ""}`}>
                            <div className="flex text-gray-dark text-sm items-center">
                                <ChartIcon />
                                <div>Iskustvo</div>
                            </div>
                            <div className="md:mt-2 font-medium">
                                {job.levels}
                            </div>
                        </div>
                        {job.sallary && (
                            <div>
                                <div className="flex text-gray-dark text-sm items-center">
                                    <ClockIcon />
                                    <div>Plata</div>
                                </div>
                                <div className="md:mt-2 font-medium">
                                    {job.sallary ? job.sallary : "N/A"}
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="flex text-gray-dark text-sm items-center">
                                <ClockIcon />
                                <div>Rok prijave</div>
                            </div>
                            <div className="md:mt-2 font-medium">
                                još {expiringIn.days} dana
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
}
