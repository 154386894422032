export default function ChartIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
        >
            <rect
                x="6.38208"
                y="1"
                width="3.23568"
                height="13.7849"
                rx="1.61784"
                stroke="#686781"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="1"
                y="7.33234"
                width="3.23568"
                height="7.45253"
                rx="1.61784"
                stroke="#686781"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="11.7644"
                y="4.21448"
                width="3.23568"
                height="10.5704"
                rx="1.61784"
                stroke="#686781"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
