import { useRouter } from "next/router";

export default function useSearch(city, level, tag, isSingle) {
    const router = useRouter();

    const runSearch = function () {
        let location = "";

        // If only city
        if (city?.length === 1 && isEmpty(level) && isEmpty(tag)) {
            location = `/lokacija/${city[0].value}/1`;
        }
        // If only tag
        else if (tag?.length === 1 && isEmpty(city) && isEmpty(level)) {
            location = `/tag/${tag[0].value}/1`;
        }
        // If only level
        else if (level?.length === 1 && isEmpty(city) && isEmpty(tag)) {
            location = `/iskustvo/${level[0].value}/1`;
        } else {
            const cities = city?.map((cty) => cty.value).join(",") || "";
            const tags = tag?.map((tg) => tg.value).join(",") || "";
            const levels = level?.map((lvl) => lvl.value).join(",") || "";
            // For everything else, open search page:
            location = `/pretraga/?lokacija=${cities}&tag=${tags}&iskustvo=${levels}&page=1`;
        }

        router.push(location);
    };

    return {
        runSearch,
    };
}

/* Testira da li je vrijednost prazan array ili null */
function isEmpty(value) {
    if (!value) return true;
    if (value.length == 0) return true;
    return false;
}
