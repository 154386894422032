export default function LocationIcon() {
    return (
        <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
        >
            <path
                d="M11.7692 6.38462C11.7692 9.41508 9.66349 11.1583 8.08544 12.8462C7.46666 13.5216 6.8982 14.2415 6.38462 15C5.87285 14.2429 5.3058 13.5247 4.6881 12.8512C3.11005 11.164 1 9.41795 1 6.38462C1 4.95653 1.56731 3.58693 2.57712 2.57712C3.58693 1.56731 4.95653 1 6.38462 1C7.8127 1 9.1823 1.56731 10.1921 2.57712C11.2019 3.58693 11.7692 4.95653 11.7692 6.38462V6.38462Z"
                stroke="#686781"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.23096 6.38462C4.23096 6.95586 4.45788 7.5037 4.8618 7.90762C5.26573 8.31154 5.81357 8.53847 6.3848 8.53847C6.95604 8.53847 7.50388 8.31154 7.9078 7.90762C8.31173 7.5037 8.53865 6.95586 8.53865 6.38462C8.53865 5.81338 8.31173 5.26555 7.9078 4.86162C7.50388 4.4577 6.95604 4.23077 6.3848 4.23077C5.81357 4.23077 5.26573 4.4577 4.8618 4.86162C4.45788 5.26555 4.23096 5.81338 4.23096 6.38462Z"
                stroke="#686781"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
